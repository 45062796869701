import * as React from 'react';
import { Link as ScrollLink } from 'react-scroll';
import clsx from 'clsx';
import { minTwoDigits } from '@core/utils';
import ArrayList from '@components/ArrayList';
import * as styles from './ScrollNav.module.scss';


type Props = {
  items: string[];
  className?: string;
};

function ScrollNav({ items, className }: Props) {
  return (
    <ul className={clsx(styles.nav, className)}>
      <ArrayList
        items={items}
        render={({ item, index }) => (
          <li className={styles.item}>
            <ScrollLink
              to={item}
              href={`#${item}`}
              duration={400}
              offset={0}
              className={styles.link}
              activeClass={styles.linkActive}
              spy
            >
              <span>{minTwoDigits(index + 1)}</span>
              <span className={styles.caption}>
                <ArrayList
                  items={item.split('')}
                  render={({ item }) => (
                    <span>{item.replace('-', ' ')}</span>
                  )}
                />
              </span>
            </ScrollLink>
          </li>
        )}
      />
    </ul>
  );
}

export default React.memo(ScrollNav, () => true);
