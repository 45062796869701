import * as React from 'react';
import clsx from 'clsx';
import { minTwoDigits } from '@core/utils';
import * as styles from './GallerySlideIndex.module.scss';

type Props = {
  currentIndex: number;
  totalCount: number;
  className?: string;
  captionClassName?: string;
  barClassName?: string;
  progressClassName?: string;
}

export default function GallerySlideIndex({
  currentIndex,
  totalCount,
  className,
  captionClassName,
  barClassName,
  progressClassName,
}: Props) {
  const slideNumber = currentIndex + 1;
  const progressPercent = slideNumber * 100 / totalCount;

  return (
    <div className={className}>
      <div className={clsx(styles.caption, captionClassName)}>
        {`${minTwoDigits(slideNumber)} / ${minTwoDigits(totalCount)}`}
      </div>
      <div className={clsx(styles.bar, barClassName)}>
        <div
          className={clsx(styles.progress, progressClassName)}
          style={{ width: `${progressPercent}%` }}
        />
      </div>
    </div>
  );
}
