import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { CART } from '@core/constants';
import { useRoutes } from '@core/hooks';
import { pluralize } from '@core/utils';
import FeaturedWidget from '@components/FeaturedWidget';
import { PostgresData, Bundle, CoverImage } from '@interface/gatsby';


type DataType = PostgresData<Record<'bundle', Bundle>> & {
  covers: {
    nodes: CoverImage[];
  }
}

type Props = {
  className?: string;
}

export default function FeaturedBundleWidget({ className }: Props) {
  const { postgres } = useStaticQuery<DataType>(graphql`
      query FeaturedBundles {
          postgres {
              bundle: bundleBySlug(slug: "all-weapons-all-mods") {
                  id
                  title
                  slug
                  url
                  price
                  discount
                  cover {
                      childImageSharp {
                          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, width: 1024)
                      }
                  }
                  products: bundlesProducts {
                      totalCount
                  }
              }
          }
      }
  `);
  const { route, BUNDLES } = useRoutes();

  let bundle = postgres.bundle;
  if (bundle) {
    let quantity = bundle.products.totalCount;
    return (
      <FeaturedWidget
        className={className}
        heading="Best deal"
        type={CART.ITEM.BUNDLES}
        url={route(BUNDLES)}
        data={bundle}
        description={`${quantity} ${pluralize('product', quantity)} in a bundle.`}
        buttonType="dark"
      />
    );
  }
  return null;
}
