import * as React from 'react';
import clsx from 'clsx';
import ArrayList from '@components/ArrayList';
import * as styles from './StatCard.module.scss';


type Props = {
  className?: string;
  children: React.ReactNode;
  caption: string;
}

export default function StatCard({ children, className, caption }: Props) {
  return (
    <div className={clsx(styles.container, className)}>
      <h4 className={styles.highlight}>{children}</h4>
      <ArrayList
        items={caption.split('\\n')}
        render={({ item }) => (
          <p className={styles.caption}>{item}</p>
        )}
      />
    </div>
  );
}
