import * as React from 'react';
import { Waypoint } from 'react-waypoint';
import clsx from 'clsx';
import * as styles from './Screen.module.scss';


export type ScreenCallbackArgs = Waypoint.CallbackArgs & { id: string };

type Props = {
  id: string;
  className?: string;
  children?: React.ReactNode | React.ReactNode[];
  onPositionChange?: (params: ScreenCallbackArgs) => void
}

export default function Screen({ id, className, children, onPositionChange }: Props) {
  const handlePositionChange = (params: Waypoint.CallbackArgs) => onPositionChange?.({ ...params, id });
  return (
    <Waypoint
      onPositionChange={handlePositionChange}
      topOffset="40%"
      bottomOffset="40%"
    >
      <section
        id={id}
        className={clsx(styles.screen, className)}
      >
        {children}
      </section>
    </Waypoint>
  );
}
