import * as React from 'react';
import { Link } from 'gatsby';
import clsx from 'clsx';
import ProductItem from '@components/store/ProductItem';
import { Bundle, Product } from '@interface/gatsby';
import { CartItemType } from '@interface/common';


type Props = {
  type: CartItemType;
  heading: string;
  url: string;
  data: Product | Bundle;
  className?: string;
  title?: string;
  description?: string;
  buttonType?: 'dark' | 'light';
}

function FeaturedWidget({ type, heading, url, data, title, description, className, buttonType }: Props) {
  return (
    <div className={className}>
      <h3>{heading}</h3>
      <Link to={url} className={clsx('button small outline', buttonType)}>
        View all
      </Link>
      <ProductItem
        {...data}
        type={type}
        title={title ? title : data.title}
        description={description}
        widget
      />
    </div>
  );
}

export default React.memo(FeaturedWidget, () => true);
