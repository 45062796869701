import * as React from 'react';
import clsx from 'clsx';
import { minTwoDigits } from '@core/utils';
import * as styles from './SectionCount.module.scss';


type Props = {
  currentSectionId: string;
  sections: string[];
  className?: string;
}

function SectionCount({ currentSectionId, sections, className }: Props) {
  const sectionNumber = sections.findIndex((id => id === currentSectionId)) + 1;
  return (
    <p className={clsx(styles.container, className)}>
      {minTwoDigits(sectionNumber)}
      <sup className={styles.total}>
        {minTwoDigits(sections.length)}
      </sup>
    </p>
  );
}

export default React.memo(SectionCount, () => true);
