import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { CART } from '@core/constants';
import { useRoutes } from '@core/hooks';
import FeaturedWidget from '@components/FeaturedWidget';
import { PostgresData, Product, CoverImage } from '@interface/gatsby';


type DataType = PostgresData<Record<'products', Product[]>> & {
  className?: string;
  covers: {
    nodes: CoverImage[];
  }
}

type Props = {
  className?: string;
}

export default function FeaturedProductWidget({ className }: Props) {
  const { postgres } = useStaticQuery<DataType>(graphql`
      query FeaturedProducts {
          postgres {
              products: productsList(orderBy: CREATED_AT_DESC, first: 1) {
                  id
                  title
                  slug
                  url
                  price
                  cover {
                      childImageSharp {
                          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, width: 1024)
                      }
                  }
                  category {
                      slug
                      description
                  }
              }
          }
      }
  `);
  const { route, PRODUCTS } = useRoutes();

  let products = postgres.products;
  if (products.length > 0) {
    let product = products[0];
    return (
      <FeaturedWidget
        className={className}
        heading="Recent release"
        type={CART.ITEM.PRODUCTS}
        url={route(PRODUCTS)}
        data={product}
        title={product.title}
        description={product.category.description}
        buttonType="light"
      />
    );
  }
  return null;
}
