import * as React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage, withArtDirection, ImageDataLike } from 'gatsby-plugin-image';
import clsx from 'clsx';
import { ARROW_DIRECTION, MEDIA } from '@core/constants';
import { formatThousand, getSiteMetaData, mailToURL } from '@core/utils';
import BaseHead from '@components/Head';
import Main from '@components/Main';
import Screen, { ScreenCallbackArgs } from '@components/Screen';
import Centerer from '@components/Centerer';
import Hero from '@components/Hero';
import ScrollNav from '@components/ScrollNav';
import ArrowButton from '@components/ArrowButton';
import Separator from '@components/Separator';
import Link from '@components/Link';
import ArrayList from '@components/ArrayList';
import Gallery from '@components/Gallery';
import GallerySlideIndex from '@components/GallerySlideIndex';
import SectionCount from '@components/SectionCount';
import StatCard from '@components/StatCard';
import FeaturedProductWidget from '@components/store/FeaturedProductWidget';
import FeaturedBundleWidget from '@components/store/FeaturedBundleWidget';
import TrendingIcon from '@assets/trending-up.svg';
import { SiteData, Forthcoming, EdgeNode } from '@interface/gatsby';
import { PageProps } from '@interface/common';
import * as styles from './index.module.scss';


const WELCOME_ID = 'welcome';
const ABOUT_ID = 'who-we-are';
const FEATURED_ID = 'featured';
const FORTHCOMING_ID = 'forthcoming';
const CONTACT_ID = 'get-in-touch';

const NAV_SECTIONS = [WELCOME_ID, ABOUT_ID, FEATURED_ID, FORTHCOMING_ID, CONTACT_ID];


type DataType = SiteData & Forthcoming & {
  gallery: {
    edges: EdgeNode<ImageDataLike>[]
  }
  heroImageLandscape: ImageDataLike;
  heroImagePortrait: ImageDataLike;
  aboutImage: ImageDataLike;
  contactImageDistant: ImageDataLike;
  contactImageCloseup: ImageDataLike;
}

export default function IndexPage({ data }: PageProps<DataType>) {
  const { emails, statistics } = getSiteMetaData(data);
  const { forthcoming } = data;
  const heroImages = withArtDirection(getImage(data.heroImageLandscape)!, [
    {
      media: MEDIA.MOBILE_PORTRAIT,
      image: getImage(data.heroImagePortrait)!,
    },
  ]);
  const aboutImage = getImage(data.aboutImage);
  const contactImages = withArtDirection(getImage(data.contactImageDistant)!, [
    {
      media: MEDIA.MOBILE_PORTRAIT,
      image: getImage(data.contactImageCloseup)!,
    },
  ]);
  const gallery = React.useMemo(() => {
    return data.gallery.edges.map(({ node }, index) => {
      let original = getImage(node);
      return {
        original,
        originalAlt: `WIP image #${index + 1}`,
        originalWidth: original?.width,
        originalHeight: original?.height,
      };
    });
  }, [data.gallery.edges]);

  const [slideIndex, setSlideIndex] = React.useState(0);

  const handleScreenPositionChange = ({ currentPosition }: ScreenCallbackArgs) => {
    const hash = window.location.hash;
    if (hash !== '' && currentPosition !== 'inside') {
      window.history.pushState('', '', window.location.origin);
    }
  };

  const handleGallerySlideChange = (index: number) => setSlideIndex(index);

  return (
    <Main>
      <ScrollNav
        className={styles.scrollNav}
        items={NAV_SECTIONS}
      />
      <Hero
        id={WELCOME_ID}
        title={() => (
          <>
            <span>We handcraft</span>
            <span>Ultra <mark>realistic</mark> aaa quality</span>
            <span>Production <mark>ready</mark> Unreal Engine <mark>assets</mark>.</span>
          </>
        )}
        description="Creators of high-level game assets"
        image={heroImages}
        fullHeight
      >
        <ArrowButton
          to={ABOUT_ID}
          href={`#${ABOUT_ID}`}
          className={styles.welcome__scrollButton}
          direction={ARROW_DIRECTION.BOTTOM}
          aria-label="Next"
        />
        <SectionCount
          currentSectionId={WELCOME_ID}
          sections={NAV_SECTIONS}
          className={styles.welcome__counter}
        />
      </Hero>
      <Screen
        id={ABOUT_ID}
        className={styles.about}
      >
        <Centerer className={styles.screen__horizontalCenterer}>
          <div className={styles.screen__verticalCenterer}>
            <div className={styles.about__brand}>
              <SectionCount
                currentSectionId={ABOUT_ID}
                sections={NAV_SECTIONS}
                className={styles.about__brand__counter}
              />
              <div className={styles.about__brand__block}>
                <div className={styles.about__brand__block__inner}>
                  <p>A modern digital factory, founded by a team of <span>CG enthusiasts.</span></p>
                </div>
              </div>
            </div>
            <div className={styles.about__image}>
              {aboutImage && (
                <div className={styles.about__image__inner}>
                  <h2 className={clsx(styles.title, styles.about__title)}>
                    <span>Who we are.</span>
                  </h2>
                  <GatsbyImage
                    image={aboutImage}
                    alt="Hero image"
                  />
                </div>
              )}
            </div>
            <div className={styles.about__description}>
              <div className={styles.about__block}>
                <h3 className={styles.about__block__title}>Mission</h3>
                <p className={styles.about__block__description}>
                  We help single developers and indie teams to jump on the train of game dev industry visual standard.
                </p>
              </div>
              <div className={clsx(styles.about__block, styles.highlight)}>
                <h3 className={styles.about__block__title}>Approach</h3>
                <ul className={styles.about__block__description}>
                  <li>Attention to details.</li>
                  <li>Variability.</li>
                  <li>Smooth animation in a balance between control and realism.</li>
                  <li>Performance optimizations.</li>
                </ul>
              </div>
              <div className={styles.about__block}>
                <h3 className={styles.about__block__title}>Competences</h3>
                <ul className={styles.about__block__description}>
                  <li>Concept art.</li>
                  <li>3d modeling.</li>
                  <li>Character design.</li>
                  <li>3d animation.</li>
                  <li>Texturing.</li>
                  <li>Development.</li>
                  <li>Sound design.</li>
                </ul>
              </div>
            </div>
          </div>
        </Centerer>
      </Screen>
      <Screen
        id={FEATURED_ID}
        className={styles.featured}
      >
        <Centerer className={styles.featured__centerer}>
          <div className={styles.featured__header}>
            <div className={styles.featured__header__block}>
              <h2 className={clsx(styles.title, styles.featured__header__block__title)}>
                <span>Featured.</span>
              </h2>
            </div>
            <SectionCount
              currentSectionId={FEATURED_ID}
              sections={NAV_SECTIONS}
              className={styles.featured__header__block__counter}
            />
          </div>
          <div className={styles.featured__container}>
            <FeaturedProductWidget className={styles.featured__container__item}/>
            <FeaturedBundleWidget className={styles.featured__container__item}/>
          </div>
          <div className={styles.screen__footer}>
            <StatCard caption="customers">
              {formatThousand(statistics.count.customers)}<small>K</small>+
            </StatCard>
            <Separator>from</Separator>
            <StatCard caption="countries">
              {statistics.count.countries}
            </StatCard>
          </div>
        </Centerer>
      </Screen>
      <Screen
        id={FORTHCOMING_ID}
        className={styles.forthcoming}
        onPositionChange={handleScreenPositionChange}
      >
        <Centerer className={styles.forthcoming__centerer}>
          <div className={styles.forthcoming__header}>
            <SectionCount
              className={styles.forthcoming__header__counter}
              currentSectionId={FORTHCOMING_ID}
              sections={NAV_SECTIONS}
            />
            <h2 className={clsx(styles.title, styles.forthcoming__header__title)}>Forthcoming.</h2>
          </div>
          <div className={styles.forthcoming__container}>
            <div className={styles.forthcoming__container__inner}>
              <div className={clsx('format', styles.forthcoming__container__content)}>
                <div className={styles.forthcoming__container__content__item}>
                  <h3>Work in progress</h3>
                  <p>We are proud to show you several renders of our unfinished work for future releases.</p>
                </div>
                <div className={styles.forthcoming__container__content__item}>
                  <h3>To be announced</h3>
                  <ul>
                    <ArrayList
                      items={forthcoming.releases}
                      render={({ item }) => (
                        <li>{item}</li>
                      )}
                    />
                  </ul>
                </div>
              </div>
              <GallerySlideIndex
                currentIndex={slideIndex}
                totalCount={gallery.length}
              />
            </div>
            <Gallery
              className={styles.forthcoming__container__gallery}
              items={gallery}
              onSlideChange={handleGallerySlideChange}
            />
          </div>
          <div className={clsx(styles.screen__footer, styles.forthcoming__footer)}>
            <StatCard caption="tris poly count\nfor base LOD">
              ~30<small>K</small>-50<small>K</small>
            </StatCard>
            <Separator>with</Separator>
            <StatCard caption="overall resolution\nper weapon">
              <TrendingIcon/>16<small>K</small>
              {/*<small>🡑</small>16<small>K</small>*/}
            </StatCard>
          </div>
        </Centerer>
      </Screen>
      <Hero
        className={styles.contact}
        id={CONTACT_ID}
        title={() => (
          <>
            <span>Let’s <mark>build</mark></span>
            <span>Something <mark>great</mark></span>
            <span><mark>together</mark>.</span>
          </>
        )}
        description="The virtual world more real than reality"
        image={contactImages}
        halfHeight
      >
        <div className={styles.contact__caption}>
          <SectionCount
            currentSectionId={CONTACT_ID}
            sections={NAV_SECTIONS}
            className={styles.contact__counter}
          />
          <div className={styles.contact__inner}>
            <h3 className={styles.contact__title}>Get in touch</h3>
            <p className={styles.contact__description}>
              If you have any questions, please feel free to drop us a line. We'd love to hear from you.
            </p>
            <Link
              className={clsx('button large light stretch', styles.contact__button)}
              to={mailToURL({ to: emails.contact })}
            >
              {emails.contact}
            </Link>
          </div>
        </div>
      </Hero>
    </Main>
  );
}

export function Head({ data }: PageProps<DataType>) {
  const { meta } = getSiteMetaData(data);

  return (
    <BaseHead
      description={meta.description}
      keywords={meta.keywords}
    />
  );
}

export const query = graphql`
    query IndexPage {
        site {
            siteMetadata {
                meta {
                    description
                    keywords
                }
                emails {
                    contact
                }
                statistics {
                    count {
                        customers
                        countries
                    }
                }
            }
        }
        forthcoming: yaml(releases: { ne: null }) {
            releases
        }
        gallery: allFile(
            filter: {
                sourceInstanceName: { eq: "forthcoming" },
                extension: { in: ["jpg"] }
            }
            sort: { fields: name, order: ASC }
        ) {
            edges {
                node {
                    childImageSharp {
                        gatsbyImageData(
                            layout: CONSTRAINED
                            placeholder: BLURRED
                        )
                    }
                }
            }
        }
        heroImageLandscape: file(
            absolutePath: { glob: "**/src/images/hero-landscape.jpg" }
        ) {
            childImageSharp {
                gatsbyImageData(
                    layout: CONSTRAINED
                    placeholder: BLURRED
                )
            }
        }
        heroImagePortrait: file(
            absolutePath: { glob: "**/src/images/hero-portrait.jpg" }
        ) {
            childImageSharp {
                gatsbyImageData(
                    layout: CONSTRAINED
                    aspectRatio: 0.5625
                    placeholder: BLURRED
                )
            }
        }
        aboutImage: file(
            absolutePath: { glob: "**/src/images/about.jpg" }
        ) {
            childImageSharp {
                gatsbyImageData(
                    layout: CONSTRAINED
                    breakpoints: [750, 1024]
                    aspectRatio: 0.5625
                    width: 1024
                    quality: 75
                    placeholder: BLURRED
                )
            }
        }
        contactImageDistant: file(
            absolutePath: { glob: "**/src/images/contact-distant.jpg" }
        ) {
            ...BackgroundImage
        }
        contactImageCloseup: file(
            absolutePath: { glob: "**/src/images/contact-closeup.jpg" }
        ) {
            ...BackgroundImage
        }
    }
`;
